import React from 'react';
import { imageHandler } from '../../utils/imageHandler';
import './style.css';

const Rating = ({ rating }) => {
    return (
        <div className="rating">
            {
                Array.from({ length: rating }).map((s, index) =>
                    <img
                        key={index}
                        src={imageHandler("star")}
                        alt="rating"
                    />
                )
            }
        </div>
    );
}

export default Rating;
