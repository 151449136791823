import "./style.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div>
                <nav className="nav-footer">
                    <ul>
                        <li><a href=''>Instagram</a></li>
                        <li><a href=''>Twitter</a></li>
                        <li><a href=''>YouTube</a></li>
                    </ul>
                    <p> BeTravel כל הזכויות שמורות</p>
                </nav>
            </div>
        </footer>
    );
};

export default Footer;