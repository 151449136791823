import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import "./style.css";
import countries from "../../assets/data/countries"; // לייבוא רשימת המדינות

const FiltersCountries = ({ setCountry }) => {
    return (
        <div className="filtersCountries">
            <Autocomplete
                disablePortal
                options={countries}
                renderInput={(params) => <TextField {...params} label="Country" />}
                onChange={e => {
                    setCountry(e.target.textContent.replace(/[^\w\s]/g, '').trim())
                }}
            />
        </div>
    );
}

export default FiltersCountries;
