import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import "./style.css";

const hotels = [
    'Aaron hotel',
    'Babka hotel',
    'Crown Plaza',
];

const HotelsFilter = () => {
    // const [hotelsName, sethotelsName] = useState('')

    return (
        <Autocomplete
            className="autoComplete"
            disablePortal
            options={hotels}
            renderInput={(params) => <TextField {...params} label="Hotel" />}
        />

    )
}

export default HotelsFilter;