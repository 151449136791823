import Header from "./header";
import Footer from "./footer";

export const Layout = ({ children }) => (
    <div className="layout">
        <Header />
        {children}
        <Footer />
    </div>
);




