
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from '../pages/home';
import FlightsPage from '../pages/flights';
import HotelsPage from '../pages/hotels';
import DealsPage from '../pages/deals';
import VisasPage from '../pages/visas';
import AttractionsPage from '../pages/attractions';
import ContactPage from '../pages/contact';
import { Layout } from './layout';

export const AppRouter = () => (
  <BrowserRouter>
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/flights" element={<FlightsPage/>} />
        <Route path="/hotels" element={<HotelsPage/>} />
        <Route path="/deals" element={<DealsPage/>} />
        <Route path="/visas" element={<VisasPage/>} />
        <Route path="/attractions" element={<AttractionsPage/>} />
        <Route path="/contact" element={<ContactPage/>} />
      </Routes>
    </Layout>
  </BrowserRouter>
);