import axios from 'axios';
import { SectionWrapper } from '../../components/sectionWrapper';
import { useFlights } from "../../hooks/useFlights";
import "./style.css"
import React from "react";
import Map from "../../components/map";
import Filters from '../../components/filters'

const Home = () => {
    const [locations, setLocations] = React.useState([]);
    const [country, setCountry] = React.useState('Israel');

    React.useEffect(() => {
        axios.get(`https://backend.becompany.net/business/?category=Hotels&country=${country}&format=json`)
            .then(result => {
                debugger;
                setLocations(result.data.results);
            })
    }, [country]);

    return (
        <div name="section-content">
            <SectionWrapper>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Filters
                        country={country}
                        setCountry={setCountry}
                    />
                    <Map locations={locations} />
                </div>
            </SectionWrapper>
            <SectionWrapper>
                <h1>Deals</h1>
            </SectionWrapper>
            <SectionWrapper>
                <h1>Attractions</h1>
            </SectionWrapper>
        </div>
    );
};

export default Home;