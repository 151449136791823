import React, { useState } from "react";
import "./style.css";
import CurrenciesFilter from '../filtersCurrencies'
import CountryFilter from '../filtersCountries';
import DateFilter from "../datePicker";
import HotelsFilter from "../hotelsFilter";


const Filters = ({ country, setCountry }) => {

    const onSubmit = e => {
        e.preventDefault();
    };

    return (
        <form onSubmit={onSubmit} className="filters">
            <CurrenciesFilter/>
            <CountryFilter country={country} setCountry={setCountry}/>
            <HotelsFilter />
            <DateFilter />
            <button className="goButton">GO</button>
        </form>
    )
}

export default Filters;