import { AppRouter } from '../AppRouter';
import HotelsComponent from '../map/hotelsComponent';
// import Hotel from '../map/hotelsComponent/index2';
// import { APIProvider, Map as GoogleMap } from '@vis.gl/react-google-maps';

const App = () => {
  return (
    <div>
      <AppRouter />
      {/* <HotelsComponent /> */}
      {/* <GoogleMap/>
      <Marker/>
      <Hotel/> */}
      
    </div>
  );
}

export default App;
