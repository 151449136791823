import React from 'react'
import { APIProvider, Map as GoogleMap } from '@vis.gl/react-google-maps';
import { imageHandler } from '../../utils/imageHandler';
import './style.css';
import CustomMarker from '../customMarker';

const containerStyle = {
  width: '100vw',
  height: '75vh',
};

const center = {
  lat: 32.08152590957794,
  lng: 34.7681377426302
};

const Map = ({ locations = [] }) => {
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap mapId="gmimap0" defaultZoom={10} defaultCenter={center} style={containerStyle}>
        {locations.map((location, index) => (
          <CustomMarker key={index} location={location} />
        ))}
      </GoogleMap>
    </APIProvider>
  );
}

export default React.memo(Map)

