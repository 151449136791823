import { imageHandler } from "../../utils/imageHandler"
import { SectionWrapper } from '../../components/sectionWrapper';
import "./style.css"

const Visas = () => (
    <SectionWrapper>
        <div name='section-img'>
            <img src={imageHandler("visas")} />
            <div name='section-overlay' />
        </div>
        <div name='section-content'>
            <h1>Visas</h1>
        </div>
    </SectionWrapper>
);

export default Visas;