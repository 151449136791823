import { imageHandler } from "../../utils/imageHandler"
import { SectionWrapper } from '../../components/sectionWrapper';
import "./style.css"

const Attractions = () => (
    <SectionWrapper>
        <div name='section-content'>
            <h1>Attractions</h1>
        </div>
        <div name='section-img'>
            <img src={imageHandler("attractions")} />
            <div name='section-overlay' />
        </div>
    </SectionWrapper>
);

export default Attractions;