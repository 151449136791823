import { imageHandler } from "../../utils/imageHandler"
import { SectionWrapper } from '../../components/sectionWrapper';
import "./style.css"

const Home = () => (
    <SectionWrapper>
        <div name='section-content'>
            <h1>Flights</h1>
        </div>
        <div name='section-img'>
            <img src={imageHandler("flights")} />
            <div name='section-overlay' />
        </div>
    </SectionWrapper>
);

export default Home;