import { useState } from "react"
import './style.css'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';


const DatePicker = () => {
    // const [checkInDate, setcheckInDate] = useState('')
    // const [checkOutDate, setcheckOutDate] = useState('')

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
                components={['DatePicker']}
                // sx={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <div className="datePickersContainer">
                    <MuiDatePicker className="checkIn"
                        label="Check In"
                    />
                    <MuiDatePicker className="checkOut"
                        label="Check Out"
                    />
                </div>
            </DemoContainer>
        </LocalizationProvider>
    )
}

export default DatePicker;
