import './style.css';

export const SectionWrapper = ({ children }) => (
  <div className="section-wrapper">{children}</div>
);










