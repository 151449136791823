import { imageHandler } from "../../utils/imageHandler"
import { SectionWrapper } from '../../components/sectionWrapper';
import "./style.css"
import HotelsFilter from "../../components/hotelsFilter";
import DateFilter from "../../components/datePicker";

const Hotels = () => (

    <SectionWrapper>
        <div name='section-img'>
            <img src={imageHandler("hotels")} />
            <div name='section-overlay' />
        </div>
        <div className="hotelFilter">
        <HotelsFilter />
        </div>
        <div className="dateFilter">
        <DateFilter />
        </div>
        <button className="goButton">GO</button>
        <div name='section-content'>
            <h1>Hotels</h1>
        </div>
    </SectionWrapper>
);

export default Hotels;