import React, { useState, useEffect } from "react";
import "./style.css";
import currencyData from "../../assets/data/currencies"; // לייבוא רשימת המטבעות

const FiltersCurrencies = () => {
    const [currency, setCurrency] = useState("₪");

    return (
        <select
            className="filtersCurrencies"
            value={currency}
            onChange={e => {
                setCurrency(e.target.value);
            }}
        >
            <option value="">Currencies</option>
            {currencyData.map((currency, i) => (
                <option key={`${currency.symbol}-${i}`} value={currency.symbol}>
                    {/* {currency.name} ''
                    {currency.code} '' */}
                    {currency.symbol}
                </option>
            ))}
        </select>
    );
}

export default FiltersCurrencies;
