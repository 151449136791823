const images = {
    flights: require("../assets/images/flights.jpeg"),
    hotels: require("../assets/images/hotels.jpeg"),
    deals: require("../assets/images/deals.jpeg"),
    attractions: require("../assets/images/attractions.jpeg"),
    locationsHilton: require("../assets/images/location-hillton.jpg"),
    locationsHerods: require("../assets/images/location-herods.jpg"),
    locationsRoyalBeach: require("../assets/images/location-royalbeach.jpg"),
    locationsSheraton: require("../assets/images/location-sheraton.jpg"),
    locationsCrownPlaza: require("../assets/images/location-crownplaza.jpg"),
    locationsDan: require("../assets/images/location-dan.jpg"),
    locationsOrchid: require("../assets/images/location-orchid.jpg"),
    star: require("../assets/images/star.png"),
    




    // TODO: add photos for "home", "visas" and "contact"
}

export const imageHandler = (img) => {
    if (img in images) {
        return images[img];
    } else {
        // return images["hotels"];
        return "https://placehold.co/400x700";
    }
}

