const currencies = [
    { name: 'Afghan Afghani', code: 'AFN', symbol: '؋' },
    { name: 'Albanian Lek', code: 'ALL', symbol: 'Lek' },
    { name: 'Algerian Dinar', code: 'DZD', symbol: 'د.ج' },
    { name: 'Andorran Franc', code: 'ADP', symbol: 'Pts' },
    { name: 'Angolan Kwanza', code: 'AOA', symbol: 'Kz' },
    { name: 'East Caribbean Dollar', code: 'XCD', symbol: '$' },
    { name: 'Argentine Peso', code: 'ARS', symbol: '$' },
    { name: 'Armenian Dram', code: 'AMD', symbol: '֏' },
    { name: 'Australian Dollar', code: 'AUD', symbol: '$' },
    { name: 'Azerbaijani Manat', code: 'AZN', symbol: '₼' },
    { name: 'Bahamian Dollar', code: 'BSD', symbol: '$' },
    { name: 'Bahraini Dinar', code: 'BHD', symbol: 'ب.د' },
    { name: 'Bangladeshi Taka', code: 'BDT', symbol: '৳' },
    { name: 'Barbadian Dollar', code: 'BBD', symbol: '$' },
    { name: 'Belarusian Ruble', code: 'BYN', symbol: 'Br' },
    { name: 'Belgian Franc', code: 'BEF', symbol: 'FB' },
    { name: 'Belize Dollar', code: 'BZD', symbol: '$' },
    { name: 'Beninese Franc', code: 'XOF', symbol: 'Fr' },
    { name: 'Bhutanese Ngultrum', code: 'BTN', symbol: 'Nu.' },
    { name: 'Bolivian Boliviano', code: 'BOB', symbol: '$b' },
    { name: 'Bosnia and Herzegovina Convertible Mark', code: 'BAM', symbol: 'KM' },
    { name: 'Botswana Pula', code: 'BWP', symbol: 'P' },
    { name: 'Brazilian Real', code: 'BRL', symbol: 'R$' },
    { name: 'Brunei Dollar', code: 'BND', symbol: '$' },
    { name: 'Bulgarian Lev', code: 'BGN', symbol: 'лв' },
    { name: 'Burundian Franc', code: 'BIF', symbol: 'Fr' },
    { name: 'Cambodian Riel', code: 'KHR', symbol: '៛' },
    { name: 'Cameroonian Franc', code: 'XAF', symbol: 'Fr' },
    { name: 'Canadian Dollar', code: 'CAD', symbol: '$' },
    { name: 'Cape Verdean Escudo', code: 'CVE', symbol: '$' },
    { name: 'Cayman Islands Dollar', code: 'KYD', symbol: '$' },
    { name: 'Central African CFA Franc', code: 'XAF', symbol: 'Fr' },
    { name: 'Chilean Peso', code: 'CLP', symbol: '$' },
    { name: 'Chinese Yuan', code: 'CNY', symbol: '¥' },
    { name: 'Colombian Peso', code: 'COP', symbol: '$' },
    { name: 'Comorian Franc', code: 'KMF', symbol: 'Fr' },
    { name: 'Congolese Franc', code: 'CDF', symbol: 'Fr' },
    { name: 'Costa Rican Colón', code: 'CRC', symbol: '₡' },
    { name: 'Croatian Kuna', code: 'HRK', symbol: 'kn' },
    { name: 'Cuban Peso', code: 'CUP', symbol: '$' },
    { name: 'Czech Koruna', code: 'CZK', symbol: 'Kč' },
    { name: 'Danish Krone', code: 'DKK', symbol: 'kr' },
    { name: 'Djiboutian Franc', code: 'DJF', symbol: 'Fr' },
    { name: 'Dominican Peso', code: 'DOP', symbol: '$' },
    { name: 'Egyptian Pound', code: 'EGP', symbol: '£' },
    { name: 'Equatorial Guinean Franc', code: 'XAF', symbol: 'Fr' },
    { name: 'Eritrean Nakfa', code: 'ERN', symbol: 'Nfk' },
    { name: 'Estonian Kroon', code: 'EEK', symbol: 'kr' },
    { name: 'Ethiopian Birr', code: 'ETB', symbol: 'Br' },
    { name: 'Fijian Dollar', code: 'FJD', symbol: '$' },
    { name: 'Finnish Markka', code: 'FIM', symbol: 'mk' },
    { name: 'Fijian Dollar', code: 'FJD', symbol: '$' },
    { name: 'French Franc', code: 'FRF', symbol: 'Fr' },
    { name: 'Gabonese Franc', code: 'XAF', symbol: 'Fr' },
    { name: 'Gambian Dalasi', code: 'GMD', symbol: 'D' },
    { name: 'Georgian Lari', code: 'GEL', symbol: '₾' },
    { name: 'German Mark', code: 'DEM', symbol: 'DM' },
    { name: 'Ghanaian Cedi', code: 'GHS', symbol: '₵' },
    { name: 'Gibraltar Pound', code: 'GIP', symbol: '£' },
    { name: 'Greek Drachma', code: 'GRD', symbol: 'Δρχ' },
    { name: 'Grenadian Dollar', code: 'XCD', symbol: '$' },
    { name: 'Guatemalan Quetzal', code: 'GTQ', symbol: 'Q' },
    { name: 'Guinean Franc', code: 'GNF', symbol: 'Fr' },
    { name: 'Guyanese Dollar', code: 'GYD', symbol: '$' },
    { name: 'Haitian Gourde', code: 'HTG', symbol: 'G' },
    { name: 'Honduran Lempira', code: 'HNL', symbol: 'L' },
    { name: 'Hong Kong Dollar', code: 'HKD', symbol: '$' },
    { name: 'Hungarian Forint', code: 'HUF', symbol: 'Ft' },
    { name: 'Icelandic Króna', code: 'ISK', symbol: 'kr' },
    { name: 'Indian Rupee', code: 'INR', symbol: '₹' },
    { name: 'Indonesian Rupiah', code: 'IDR', symbol: 'Rp' },
    { name: 'Iranian Rial', code: 'IRR', symbol: '﷼' },
    { name: 'Iraqi Dinar', code: 'IQD', symbol: 'ع.د' },
    { name: 'Irish Pound', code: 'IEP', symbol: '£' },
    { name: 'Israeli New Shekel', code: 'ILS', symbol: '₪' },
    { name: 'Italian Lira', code: 'ITL', symbol: '₠' },
    { name: 'Jamaican Dollar', code: 'JMD', symbol: '$' },
    { name: 'Japanese Yen', code: 'JPY', symbol: '¥' },
    { name: 'Jordanian Dinar', code: 'JOD', symbol: 'د.أ' },
    { name: 'Kazakhstani Tenge', code: 'KZT', symbol: '₸' },
    { name: 'Kenyan Shilling', code: 'KES', symbol: 'KSh' },
    { name: 'Kuwaiti Dinar', code: 'KWD', symbol: 'د.ك' },
    { name: 'Kyrgyzstani Som', code: 'KGS', symbol: 'лв' },
    { name: 'Laotian Kip', code: 'LAK', symbol: '₭' },
    { name: 'Latvian Lats', code: 'LVL', symbol: 'Ls' },
    { name: 'Lebanese Pound', code: 'LBP', symbol: 'ل.ل' },
    { name: 'Liberian Dollar', code: 'LRD', symbol: '$' },
    { name: 'Libyan Dinar', code: 'LYD', symbol: 'د.ل' },
    { name: 'Liechtenstein Franc', code: 'CHF', symbol: 'CHF' },
    { name: 'Lithuanian Litas', code: 'LTL', symbol: 'Lt' },
    { name: 'Luxembourgian Franc', code: 'LUF', symbol: '₣' },
    { name: 'Malagasy Ariary', code: 'MGA', symbol: 'Ar' },
    { name: 'Malawian Kwacha', code: 'MWK', symbol: 'MK' },
    { name: 'Malaysian Ringgit', code: 'MYR', symbol: 'RM' },
    { name: 'Maldivian Rufiyaa', code: 'MVR', symbol: 'Rf' },
    { name: 'Mauritanian Ouguiya', code: 'MRU', symbol: 'UM' },
    { name: 'Mauritian Rupee', code: 'MUR', symbol: '₨' },
    { name: 'Mexican Peso', code: 'MXN', symbol: '$' },
    { name: 'Moldovan Leu', code: 'MDL', symbol: 'Leu' },
    { name: 'Monegasque Franc', code: 'MCF', symbol: '₣' },
    { name: 'Mongolian Tugrik', code: 'MNT', symbol: '₮' },
    { name: 'Moroccan Dirham', code: 'MAD', symbol: 'د.م.' },
    { name: 'Mozambican Metical', code: 'MZN', symbol: 'MT' },
    { name: 'Myanma Kyat', code: 'MMK', symbol: 'Ks' },
    { name: 'Namibian Dollar', code: 'NAD', symbol: '$' },
    { name: 'Nepalese Rupee', code: 'NPR', symbol: 'Rs' },
    { name: 'Netherlands Antillean Guilder', code: 'ANG', symbol: 'ƒ' },
    { name: 'New Zealand Dollar', code: 'NZD', symbol: '$' },
    { name: 'Nigerian Naira', code: 'NGN', symbol: '₦' },
    { name: 'North Korean Won', code: 'KPW', symbol: '₩' },
    { name: 'Norwegian Krone', code: 'NOK', symbol: 'kr' },
    { name: 'Omani Rial', code: 'OMR', symbol: 'ر.ع.' },
    { name: 'Pakistani Rupee', code: 'PKR', symbol: '₨' },
    { name: 'Panamanian Balboa', code: 'PAB', symbol: 'B/. ' },
    { name: 'Papua New Guinean Kina', code: 'PGK', symbol: 'K' },
    { name: 'Paraguayan Guarani', code: 'PYG', symbol: '₲' },
    { name: 'Peruvian Nuevo Sol', code: 'PEN', symbol: 'S/.' },
    { name: 'Philippine Peso', code: 'PHP', symbol: '₱' },
    { name: 'Polish Zloty', code: 'PLN', symbol: 'zł' },
    { name: 'Portuguese Escudo', code: 'PTE', symbol: 'Esc' },
    { name: 'Qatari Rial', code: 'QAR', symbol: 'ر.ق' },
    { name: 'Romanian Leu', code: 'RON', symbol: 'lei' },
    { name: 'Russian Ruble', code: 'RUB', symbol: '₽' },
    { name: 'Rwandan Franc', code: 'RWF', symbol: 'Fr' },
    { name: 'Saint Helena Pound', code: 'SHP', symbol: '£' },
    { name: 'Samoan Tala', code: 'WST', symbol: 'T' },
    { name: 'San Marinese Lira', code: 'SML', symbol: '₤' },
    { name: 'São Tomé and Príncipe Dobra', code: 'STN', symbol: 'Db' },
    { name: 'Saudi Riyal', code: 'SAR', symbol: 'ر.س' },
    { name: 'Serbian Dinar', code: 'RSD', symbol: 'дин.' },
    { name: 'Seychellois Rupee', code: 'SCR', symbol: '₨' },
    { name: 'Sierra Leonean Leone', code: 'SLL', symbol: 'Le' },
    { name: 'Singapore Dollar', code: 'SGD', symbol: '$' },
    { name: 'Slovak Koruna', code: 'SKK', symbol: 'Sk' },
    { name: 'Slovenian Tolar', code: 'SIT', symbol: 'SIT' },
    { name: 'Solomon Islands Dollar', code: 'SBD', symbol: '$' },
    { name: 'Somali Shilling', code: 'SOS', symbol: 'S' },
    { name: 'South African Rand', code: 'ZAR', symbol: 'R' },
    { name: 'South Korean Won', code: 'KRW', symbol: '₩' },
    { name: 'South Sudanese Pound', code: 'SSP', symbol: '£' },
    { name: 'Spanish Peseta', code: 'ESP', symbol: '₧' },
    { name: 'Sri Lankan Rupee', code: 'LKR', symbol: 'Rs' },
    { name: 'Sudanese Pound', code: 'SDG', symbol: 'ج.س.' },
    { name: 'Surinamese Dollar', code: 'SRD', symbol: '$' },
    { name: 'Swedish Krona', code: 'SEK', symbol: 'kr' },
    { name: 'Swiss Franc', code: 'CHF', symbol: 'CHF' },
    { name: 'Syrian Pound', code: 'SYP', symbol: '£' },
    { name: 'Tajikistani Somoni', code: 'TJS', symbol: 'ЅМ' },
    { name: 'Tanzanian Shilling', code: 'TZS', symbol: 'TSh' },
    { name: 'Thai Baht', code: 'THB', symbol: '฿' },
    { name: 'Togolese Franc', code: 'XOF', symbol: 'Fr' },
    { name: 'Tongan Paʻanga', code: 'TOP', symbol: 'T$' },
    { name: 'Trinidad and Tobago Dollar', code: 'TTD', symbol: '$' },
    { name: 'Tunisian Dinar', code: 'TND', symbol: 'د.ت' },
    { name: 'Turkish Lira', code: 'TRY', symbol: '₺' },
    { name: 'Turkmenistani Manat', code: 'TMT', symbol: 'T' },
    { name: 'U.S. Dollar', code: 'USD', symbol: '$' },
    { name: 'Ugandan Shilling', code: 'UGX', symbol: 'USh' },
    { name: 'Ukrainian Hryvnia', code: 'UAH', symbol: '₴' },
    { name: 'United Arab Emirates Dirham', code: 'AED', symbol: 'د.إ' },
    { name: 'United States Dollar', code: 'USD', symbol: '$' },
    { name: 'Uruguayan Peso', code: 'UYU', symbol: '$U' },
    { name: 'Uzbekistani Som', code: 'UZS', symbol: 'лв' },
    { name: 'Vanuatu Vatu', code: 'VUV', symbol: 'Vt' },
    { name: 'Venezuelan Bolívar', code: 'VES', symbol: 'Bs.S' },
    { name: 'Vietnamese Dong', code: 'VND', symbol: '₫' },
    { name: 'Yemeni Rial', code: 'YER', symbol: 'ر.ي' },
    { name: 'Zambian Kwacha', code: 'ZMW', symbol: 'ZK' },
    { name: 'Zimbabwean Dollar', code: 'ZWL', symbol: '$' }
  ];
  
  export default currencies;
  