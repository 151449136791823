import { imageHandler } from "../../utils/imageHandler"
import { SectionWrapper } from '../../components/sectionWrapper';
import "./style.css"

const Deals = () => (
    <SectionWrapper>
        <div name='section-content'>
            <h1>Deals</h1>
        </div>
        <div name='section-img'>
            <img src={imageHandler("deals")} />
            <div name='section-overlay' />
        </div>
    </SectionWrapper>
);

export default Deals;