const countries = [
    { label: 'Afghanistan 🇦🇫', iata: 'KBL' },
    { label: 'Albania 🇦🇱', iata: 'TIA' },
    { label: 'Algeria 🇩🇿', iata: 'ALG' },
    { label: 'Andorra 🇦🇩', iata: 'LEU' },
    { label: 'Angola 🇦🇴', iata: 'LAD' },
    { label: 'Antigua and Barbuda 🇦🇬', iata: 'ANU' },
    { label: 'Argentina 🇦🇷', iata: 'EZE' },
    { label: 'Armenia 🇦🇲', iata: 'EVN' },
    { label: 'Australia 🇦🇺', iata: 'SYD' },
    { label: 'Austria 🇦🇹', iata: 'VIE' },
    { label: 'Azerbaijan 🇦🇿', iata: 'GYD' },
    { label: 'Bahamas 🇧🇸', iata: 'NAS' },
    { label: 'Bahrain 🇧🇭', iata: 'BAH' },
    { label: 'Bangladesh 🇧🇩', iata: 'DAC' },
    { label: 'Barbados 🇧🇧', iata: 'BGI' },
    { label: 'Belarus 🇧🇾', iata: 'MSQ' },
    { label: 'Belgium 🇧🇪', iata: 'BRU' },
    { label: 'Belize 🇧🇿', iata: 'BZE' },
    { label: 'Benin 🇧🇯', iata: 'COO' },
    { label: 'Bhutan 🇧🇹', iata: 'PBH' },
    { label: 'Bolivia 🇧🇴', iata: 'LPB' },
    { label: 'Bosnia and Herzegovina 🇧🇦', iata: 'SJJ' },
    { label: 'Botswana 🇧🇼', iata: 'GBE' },
    { label: 'Brazil 🇧🇷', iata: 'GRU' },
    { label: 'Brunei 🇧🇳', iata: 'BWN' },
    { label: 'Bulgaria 🇧🇬', iata: 'SOF' },
    { label: 'Burkina Faso 🇧🇫', iata: 'OUA' },
    { label: 'Burundi 🇧🇮', iata: 'BJM' },
    { label: 'Cabo Verde 🇨🇻', iata: 'RAI' },
    { label: 'Cambodia 🇰🇭', iata: 'PNH' },
    { label: 'Cameroon 🇨🇲', iata: 'DLA' },
    { label: 'Canada 🇨🇦', iata: 'YYZ' },
    { label: 'Central African Republic 🇨🇫', iata: 'BGF' },
    { label: 'Chad 🇹🇩', iata: 'NDJ' },
    { label: 'Chile 🇨🇱', iata: 'SCL' },
    { label: 'China 🇨🇳', iata: 'PEK' },
    { label: 'Colombia 🇨🇴', iata: 'BOG' },
    { label: 'Comoros 🇰🇲', iata: 'HAH' },
    { label: 'Congo, Democratic Republic of the 🇨🇩', iata: 'FIH' },
    { label: 'Congo, Republic of the 🇨🇬', iata: 'BZV' },
    { label: 'Costa Rica 🇨🇷', iata: 'SJO' },
    { label: 'Croatia 🇭🇷', iata: 'ZAG' },
    { label: 'Cuba 🇨🇺', iata: 'HAV' },
    { label: 'Cyprus 🇨🇾', iata: 'LCA' },
    { label: 'Czech Republic 🇨🇿', iata: 'PRG' },
    { label: 'Denmark 🇩🇰', iata: 'CPH' },
    { label: 'Djibouti 🇩🇯', iata: 'JIB' },
    { label: 'Dominica 🇩🇲', iata: 'DOM' },
    { label: 'Dominican Republic 🇩🇴', iata: 'SDQ' },
    { label: 'Ecuador 🇪🇨', iata: 'UIO' },
    { label: 'Egypt 🇪🇬', iata: 'CAI' },
    { label: 'El Salvador 🇸🇻', iata: 'SAL' },
    { label: 'Equatorial Guinea 🇬🇶', iata: 'SSG' },
    { label: 'Eritrea 🇪🇷', iata: 'ASM' },
    { label: 'Estonia 🇪🇪', iata: 'TLL' },
    { label: 'Eswatini 🇸🇿', iata: 'SHO' },
    { label: 'Ethiopia 🇪🇹', iata: 'ADD' },
    { label: 'Fiji 🇫🇯', iata: 'NAN' },
    { label: 'Finland 🇫🇮', iata: 'HEL' },
    { label: 'France 🇫🇷', iata: 'CDG' },
    { label: 'Gabon 🇬🇦', iata: 'LBV' },
    { label: 'Gambia 🇬🇲', iata: 'BJL' },
    { label: 'Georgia 🇬🇪', iata: 'TBS' },
    { label: 'Germany 🇩🇪', iata: 'FRA' },
    { label: 'Ghana 🇬🇭', iata: 'ACC' },
    { label: 'Greece 🇬🇷', iata: 'ATH' },
    { label: 'Grenada 🇬🇩', iata: 'GND' },
    { label: 'Guatemala 🇬🇹', iata: 'GUA' },
    { label: 'Guinea 🇬🇳', iata: 'CKY' },
    { label: 'Guinea-Bissau 🇬🇼', iata: 'OXB' },
    { label: 'Guyana 🇬🇾', iata: 'GEO' },
    { label: 'Haiti 🇭🇹', iata: 'PAP' },
    { label: 'Honduras 🇭🇳', iata: 'TGU' },
    { label: 'Hungary 🇭🇺', iata: 'BUD' },
    { label: 'Iceland 🇮🇸', iata: 'KEF' },
    { label: 'India 🇮🇳', iata: 'DEL' },
    { label: 'Indonesia 🇮🇩', iata: 'CGK' },
    { label: 'Iran 🇮🇷', iata: 'IKA' },
    { label: 'Iraq 🇮🇶', iata: 'BGW' },
    { label: 'Ireland 🇮🇪', iata: 'DUB' },
    { label: 'Israel 🇮🇱', iata: 'TLV' },
    { label: 'Italy 🇮🇹', iata: 'FCO' },
    { label: 'Jamaica 🇯🇲', iata: 'KIN' },
    { label: 'Japan 🇯🇵', iata: 'NRT' },
    { label: 'Jordan 🇯🇴', iata: 'AMM' },
    { label: 'Kazakhstan 🇰🇿', iata: 'ALA' },
    { label: 'Kenya 🇰🇪', iata: 'NBO' },
    { label: 'Kiribati 🇰🇮', iata: 'TRW' },
    { label: 'Korea, Democratic People\'s Republic of 🇰🇵', iata: 'FNJ' },
    { label: 'Korea, Republic of 🇰🇷', iata: 'ICN' },
    { label: 'Kuwait 🇰🇼', iata: 'KWI' },
    { label: 'Kyrgyzstan 🇰🇬', iata: 'FRU' },
    { label: 'Laos 🇱🇦', iata: 'VTE' },
    { label: 'Latvia 🇱🇻', iata: 'RIX' },
    { label: 'Lebanon 🇱🇧', iata: 'BEY' },
    { label: 'Lesotho 🇱🇸', iata: 'MSU' },
    { label: 'Liberia 🇱🇷', iata: 'ROB' },
    { label: 'Libya 🇱🇾', iata: 'TIP' },
    { label: 'Liechtenstein 🇱🇮', iata: 'ACH' },
    { label: 'Lithuania 🇱🇹', iata: 'VNO' },
    { label: 'Luxembourg 🇱🇺', iata: 'LUX' },
    { label: 'Madagascar 🇲🇬', iata: 'TNR' },
    { label: 'Malawi 🇲🇼', iata: 'LLW' },
    { label: 'Malaysia 🇲🇾', iata: 'KUL' },
    { label: 'Maldives 🇲🇻', iata: 'MLE' },
    { label: 'Mali 🇲🇱', iata: 'BKO' },
    { label: 'Malta 🇲🇹', iata: 'MLA' },
    { label: 'Marshall Islands 🇲🇭', iata: 'MAJ' },
    { label: 'Mauritania 🇲🇷', iata: 'NKC' },
    { label: 'Mauritius 🇲🇺', iata: 'MRU' },
    { label: 'Mexico 🇲🇽', iata: 'MEX' },
    { label: 'Micronesia 🇫🇲', iata: 'PNI' },
    { label: 'Moldova 🇲🇩', iata: 'KIV' },
    { label: 'Monaco 🇲🇨', iata: 'MCM' },
    { label: 'Mongolia 🇲🇳', iata: 'ULN' },
    { label: 'Montenegro 🇲🇪', iata: 'TGD' },
    { label: 'Morocco 🇲🇦', iata: 'CMN' },
    { label: 'Mozambique 🇲🇿', iata: 'MPM' },
    { label: 'Myanmar 🇲🇲', iata: 'RGN' },
    { label: 'Namibia 🇳🇦', iata: 'WDH' },
    { label: 'Nauru 🇳🇷', iata: 'INU' },
    { label: 'Nepal 🇳🇵', iata: 'KTM' },
    { label: 'Netherlands 🇳🇱', iata: 'AMS' },
    { label: 'New Zealand 🇳🇿', iata: 'AKL' },
    { label: 'Nicaragua 🇳🇮', iata: 'MGA' },
    { label: 'Niger 🇳🇪', iata: 'NIM' },
    { label: 'Nigeria 🇳🇬', iata: 'LOS' },
    { label: 'North Macedonia 🇲🇰', iata: 'SKP' },
    { label: 'Norway 🇳🇴', iata: 'OSL' },
    { label: 'Oman 🇴🇲', iata: 'MCT' },
    { label: 'Pakistan 🇵🇰', iata: 'ISB' },
    { label: 'Palau 🇵🇼', iata: 'ROR' },
    { label: 'Panama 🇵🇦', iata: 'PTY' },
    { label: 'Papua New Guinea 🇵🇬', iata: 'POM' },
    { label: 'Paraguay 🇵🇾', iata: 'ASU' },
    { label: 'Peru 🇵🇪', iata: 'LIM' },
    { label: 'Philippines 🇵🇭', iata: 'MNL' },
    { label: 'Poland 🇵🇱', iata: 'WAW' },
    { label: 'Portugal 🇵🇹', iata: 'LIS' },
    { label: 'Qatar 🇶🇦', iata: 'DOH' },
    { label: 'Romania 🇷🇴', iata: 'OTP' },
    { label: 'Russia 🇷🇺', iata: 'SVO' },
    { label: 'Rwanda 🇷🇼', iata: 'KGL' },
    { label: 'Saint Kitts and Nevis 🇰🇳', iata: 'SKB' },
    { label: 'Saint Lucia 🇱🇨', iata: 'UVF' },
    { label: 'Saint Vincent and the Grenadines 🇻🇨', iata: 'SVD' },
    { label: 'Samoa 🇼🇸', iata: 'APW' },
    { label: 'San Marino 🇸🇲', iata: 'SAI' },
    { label: 'Sao Tome and Principe 🇸🇹', iata: 'TMS' },
    { label: 'Saudi Arabia 🇸🇦', iata: 'RUH' },
    { label: 'Senegal 🇸🇳', iata: 'DSS' },
    { label: 'Serbia 🇷🇸', iata: 'BEG' },
    { label: 'Seychelles 🇸🇨', iata: 'SEZ' },
    { label: 'Sierra Leone 🇸🇱', iata: 'FNA' },
    { label: 'Singapore 🇸🇬', iata: 'SIN' },
    { label: 'Slovakia 🇸🇰', iata: 'BTS' },
    { label: 'Slovenia 🇸🇮', iata: 'LJU' },
    { label: 'Solomon Islands 🇸🇧', iata: 'HIR' },
    { label: 'Somalia 🇸🇴', iata: 'MGQ' },
    { label: 'South Africa 🇿🇦', iata: 'JNB' },
    { label: 'South Sudan 🇸🇸', iata: 'JUB' },
    { label: 'Spain 🇪🇸', iata: 'MAD' },
    { label: 'Sri Lanka 🇱🇰', iata: 'CMB' },
    { label: 'Sudan 🇸🇩', iata: 'KRT' },
    { label: 'Suriname 🇸🇷', iata: 'PBM' },
    { label: 'Sweden 🇸🇪', iata: 'ARN' },
    { label: 'Switzerland 🇨🇭', iata: 'ZRH' },
    { label: 'Syria 🇸🇾', iata: 'DAM' },
    { label: 'Taiwan 🇹🇼', iata: 'TPE' },
    { label: 'Tajikistan 🇹🇯', iata: 'DYU' },
    { label: 'Tanzania 🇹🇿', iata: 'DAR' },
    { label: 'Thailand 🇹🇭', iata: 'BKK' },
    { label: 'Togo 🇹🇬', iata: 'LFW' },
    { label: 'Tonga 🇹🇴', iata: 'TBU' },
    { label: 'Trinidad and Tobago 🇹🇹', iata: 'POS' },
    { label: 'Tunisia 🇹🇳', iata: 'TUN' },
    { label: 'Turkey 🇹🇷', iata: 'IST' },
    { label: 'Turkmenistan 🇹🇲', iata: 'ASB' },
    { label: 'Tuvalu 🇹🇻', iata: 'FUN' },
    { label: 'Uganda 🇺🇬', iata: 'EBB' },
    { label: 'Ukraine 🇺🇦', iata: 'KBP' },
    { label: 'United Arab Emirates 🇦🇪', iata: 'DXB' },
    { label: 'United Kingdom 🇬🇧', iata: 'LHR' },
    { label: 'United States 🇺🇸', iata: 'JFK' },
    { label: 'Uruguay 🇺🇾', iata: 'MVD' },
    { label: 'Uzbekistan 🇺🇿', iata: 'TAS' },
    { label: 'Vanuatu 🇻🇺', iata: 'VLI' },
    { label: 'Vatican City 🇻🇦', iata: 'VCT' },
    { label: 'Venezuela 🇻🇪', iata: 'CCS' },
    { label: 'Vietnam 🇻🇳', iata: 'SGN' },
    { label: 'Yemen 🇾🇪', iata: 'SAH' },
    { label: 'Zambia 🇿🇲', iata: 'LUN' },
    { label: 'Zimbabwe 🇿🇼', iata: 'HRE' },
  ];
  
  export default countries;