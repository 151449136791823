import React from 'react';
import { AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import './style.css';
import Rating from '../rating';

const CustomMarker = ({ location }) => {
    const [isInfoOpen, setIsInfoOpen] = React.useState(false);

    return (
        <div>
            <AdvancedMarker
                position={{ lat: location.latitude, lng: location.longitude }}
                title={location.name}
                onClick={() => setIsInfoOpen(true)}
            />
            <AdvancedMarker
                position={{ lat: location.latitude, lng: location.longitude }}
                title={location.name}
                onClick={() => setIsInfoOpen(true)}
            >
            </AdvancedMarker>
            {isInfoOpen && (
                <InfoWindow
                    position={{
                        lat: location.latitude,
                        lng: location.longitude
                    }}
                    onCloseClick={() => setIsInfoOpen(false)}
                    className="infoWindow"
                >
                    <div>
                        <img
                            src={location.image}
                            alt={location.name}
                        />
                        <h2>
                            {location.name}
                        </h2>
                        <Rating rating={location.rating} />
                    </div>
                    <button>Book</button>
                </InfoWindow>
            )}
        </div>
    );
};

export default CustomMarker;
