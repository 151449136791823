import "./style.css";

const Header = () => {
  const { pathname } = window.location;

  return (
        <nav className='nav'>
          <h1>BeTravel</h1>
          <ul>
            <li><a active={(pathname === '/').toString()} href='/'>Home</a></li>
            <li><a active={(pathname === '/flights').toString()} href='/flights'>Flights</a></li>
            <li><a active={(pathname === '/hotels').toString()} href='/hotels'>Hotels</a></li>
            <li><a active={(pathname === '/deals').toString()} href='/deals'>Deals</a></li>
            <li><a active={(pathname === '/visas').toString()} href='/visas'>Visas</a></li>
            <li><a active={(pathname === '/attractionss').toString()} href='/attractions'>Attractions</a></li>
            <li><a active={(pathname === '/contact').toString()} href='/contact'>Contact</a></li>
          </ul>
        </nav>
  );
}

export default Header;
